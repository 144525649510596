import styled from "styled-components";

export const Wrapper = styled.main<{
  rowGap?: string;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${prop => prop.rowGap || "0px"};

  * {
    margin: 0;
  }
`;

export const Section = styled.section<{
  rowGap?: string;
  marginTop?: string;
}>`
  display: flex;
  flex-direction: column;
  row-gap: ${prop => prop.rowGap || "0px"};
  margin-top: ${prop => prop.marginTop || "0px"};
`;

export const Title = styled.h1<{
  fontSize?: string;
  lineHeight?: string;
}>`
  font-family: Inter;
  font-size: ${prop => prop.fontSize || "42px"};
  font-weight: 400;
  line-height: ${prop => prop.lineHeight || "51px"};
  letter-spacing: -0.015em;
  text-align: left;
  color: #232325;
`;

export const Description = styled.p<{
  preLine?: boolean;
  maxWidth?: string;
}>`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.015em;
  text-align: left;
  ${prop => prop.preLine && "white-space: pre-line;"}
  max-width: ${prop => prop.maxWidth || "100%"};

  .bold {
    font-family: Inter-Bold;
    font-weight: 700;
  }
`;

export const GridWrap = styled.div<{
  rowGap?: string;
  columnGap?: string;
  columnCount?: number;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${prop => prop.columnCount || 1},
    minmax(min-content, 1fr)
  );
  grid-row-gap: ${prop => prop.rowGap || "0px"};
  grid-column-gap: ${prop => prop.columnGap || "0px"};
`;

export const BrandCard = styled.div`
  width: 290.91px;
  height: 88.18px;
  border-radius: 6px;
  border: 0.91px solid #b2b1b6;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HackathonCard = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 27px;

  .cover-img {
    width: 592.73px;
    height: 338.18px;
    border-radius: 12px;
    cursor: pointer;
  }

  .info {
    display: flex;
    flex-direction: column;
    row-gap: 9px;

    .title {
      /* font */
      font-family: Inter;
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: -0.015em;
      text-align: left;
      color: #232325;
    }

    .desc {
      /* font */
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -0.015em;
      text-align: left;
      color: #232325;
    }
  }
`;
