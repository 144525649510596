import React from "react";

import { useNavigate } from "react-router-dom";

import {
  ApplyButton,
  Brand,
  ButtonList,
  TelegramButton,
  Wrapper,
} from "./styled";

import TelegramSvg from "@/assets/icons/telegram.svg";
import RebuildOwnershipSvg from "@/assets/rebuild-ownership.svg";

const Header = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Brand
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={RebuildOwnershipSvg} alt='Rebuild Ownership' />
        <span>Rebuild Ownership</span>
      </Brand>
      <ButtonList>
        <TelegramButton
          onClick={() => window.open("https://t.me/dataverse_city", "_blank")}
        >
          <span>Join Community</span>
          <img src={TelegramSvg} alt='Telegram' />
        </TelegramButton>
        <ApplyButton
          onClick={() =>
            window.open(
              "https://rebuild-ownership-internet-privacy.devfolio.co/",
              "_blank",
            )
          }
        >
          Apply for Rebuild 2.0
        </ApplyButton>
      </ButtonList>
    </Wrapper>
  );
};

export default Header;
