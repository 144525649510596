import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 119px;
  border-bottom: 1px solid #b3b1bf;

  position: sticky;
  top: 0;
  background-color: #ffffff;
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  span {
    font-family: Inter-Bold;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #232325;
  }
`;

export const ApplyButton = styled.button`
  border: none;
  cursor: pointer;
  padding: 8px 17px;
  border-radius: 6px;
  background: linear-gradient(273.58deg, #007aff 0%, #4ccff8 100%);
  /* font */
  font-family: Inter-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.015em;
  text-align: left;
  color: #ffffff;
`;

export const TelegramButton = styled.button`
  border: 1px solid #000000;
  cursor: pointer;
  padding: 2px 17px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  column-gap: 2px;
  /* font */
  font-family: Inter-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: -0.015em;
  text-align: left;
  color: #69686a;
  background-color: transparent;
`;

export const ButtonList = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
