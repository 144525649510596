import React from "react";

import {
  BrandCard,
  Description,
  GridWrap,
  HackathonCard,
  Section,
  Title,
  Wrapper,
} from "./styled";

import DigitalIdentityPng from "@/assets/hackathons/digital-identity.png";
import InternetPrivacyPng from "@/assets/hackathons/internet-privacy.png";
import BlockpiPng from "@/assets/sponsors/blockpi.png";
import CeramicSvg from "@/assets/sponsors/ceramic.svg";
import DataverseSvg from "@/assets/sponsors/dataverse.svg";
import FhenixSvg from "@/assets/sponsors/fhenix.svg";
import FilecoinSvg from "@/assets/sponsors/filecoin.svg";
import LineraSvg from "@/assets/sponsors/linera.svg";
import LitSvg from "@/assets/sponsors/lit.svg";
import PadoSvg from "@/assets/sponsors/pado.svg";
import ParticleNetworkSvg from "@/assets/sponsors/particle-network.svg";
import PolybaseSvg from "@/assets/sponsors/polybase.svg";
import PseSvg from "@/assets/sponsors/pse.svg";
import TablelandSvg from "@/assets/sponsors/tableland.svg";

const sponsorList: {
  img: string;
  width?: string | number;
  height?: string | number;
}[] = [
  {
    img: DataverseSvg,
    width: "216px",
  },
  {
    img: CeramicSvg,
  },
  {
    img: LitSvg,
  },
  {
    img: FilecoinSvg,
  },
  {
    img: PseSvg,
  },
  {
    img: FhenixSvg,
  },
  {
    img: TablelandSvg,
  },
  {
    img: ParticleNetworkSvg,
  },
  {
    img: PolybaseSvg,
  },
  {
    img: PadoSvg,
  },
  {
    img: BlockpiPng,
    width: "216px",
  },
  {
    img: LineraSvg,
  },
];

const hackathonList = [
  {
    img: DigitalIdentityPng,
    title: "Rebuild Ownership - Digital Identity",
    desc: "June 8th - June 25th , 2023",
    link: "https://rebuild-ownership.devfolio.co/",
  },
  {
    img: InternetPrivacyPng,
    title: "Rebuild Ownership - Internet Privacy",
    desc: "Nov 10th - Dec 22th , 2023",
    link: "https://rebuild-ownership-internet-privacy.devfolio.co/",
  },
];

const Home: React.FC = () => {
  return (
    <Wrapper rowGap='87px'>
      <Section rowGap='15px'>
        <Title>Rebuild Ownership</Title>
        <Description maxWidth='783px'>
          Rebuild Ownership as a series global hackathon, is designed to gather
          the pioneer protocols in the space and offer hackers a holistic
          experience of tackling on internet privacy using web3 technologies. We
          are thrilled to have partners like Filecoin, Fhenix, etc.
          <br />
          <br />
          Registrations for
          <span className='bold'> Rebuild Ownership 2.0 </span>
          will start at Nov.10.
          <br />
          <br />
          Start date: Friday, November 10th, 2023
          <br />
          Submission date: Friday, December 22th, 2023
          <br />
          Finale and Prizegiving: Tuesday, December 26th, 2023
          {/* <br />
          <br />
          Join our telegram group:{" "}
          <a
            href='https://t.me/dataverse_city'
            target='_blank'
            rel='noreferrer'
          >
            https://t.me/dataverse_city
          </a> */}
        </Description>
      </Section>
      <Section rowGap='30px'>
        <Title fontSize='32px' lineHeight='38.73px'>
          Supporters
        </Title>
        <GridWrap columnCount={4} rowGap='28px' columnGap='12px'>
          {sponsorList.map((item, index) => (
            <BrandCard key={index}>
              <img
                style={{
                  width: item.width ?? "auto",
                  height: item.height ?? "auto",
                }}
                src={item.img}
                alt={`sponsor-${index}`}
              />
            </BrandCard>
          ))}
        </GridWrap>
      </Section>
      <Section rowGap='27px' marginTop='12px'>
        <Title>Hackathons</Title>
        <GridWrap columnCount={2} columnGap='14.5px'>
          {hackathonList.map(item => (
            <HackathonCard key={item.title}>
              <img
                className='cover-img'
                src={item.img}
                alt={item.title}
                onClick={() => window.open(item.link, "_blank")}
              />
              <div className='info'>
                <p className='title'>{item.title}</p>
                <p className='desc'>{item.desc}</p>
              </div>
            </HackathonCard>
          ))}
        </GridWrap>
      </Section>
    </Wrapper>
  );
};

export default Home;
